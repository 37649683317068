import "../styles/ProjectCard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faEye } from '@fortawesome/free-solid-svg-icons'

function ProjectCard (props: any) {
  return (
    <div className="Project">
      <h4 className="Project-header">{props.title}</h4>
      <div>
        {props.description}
      </div>
      <div className="Project-tech">
        {props.tech}
      </div>
      <div className='Project-links'>
        <a href={props.link}>
              <FontAwesomeIcon className='Project-source' icon={faCode}/>
        </a>
        {props.live !== '' ? 
          <a href={props.live}>
              <FontAwesomeIcon className='Project-live' icon={faEye}/>
          </a>
        : null}
      </div>
    </div>
  );
}

export default ProjectCard;