import './styles/App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import ProjectCard from './components/ProjectCard';
import Footer from './components/Footer';

function App() {
  return (
      <body>
        <div className="main">
          <div>
            <p className='main-header'>portfolio of</p>
            <h1>Konsta Laurila</h1>
            <p>
              23, Computer Science and Engineering
            </p>
            <div className='contact'>
            <a href="https://www.linkedin.com/in/konstalaurila/">
                <FontAwesomeIcon className='contact-icon' icon={faLinkedin}/>
              </a>
              <a href="https://github.com/laurilako">
                <FontAwesomeIcon className='contact-icon' icon={faGithub} />
              </a>
            </div>
            <h4 className='workheader'>
              some work, more @ GitHub
            </h4>
            <div>
              <div>
                <ProjectCard tech='AI, Python (PyTorch, Tensorflow), Shell, VM, Linux, ROS2, CMake, Neural Networks, Deep-learning, CoquiTTS' live='http://jultika.oulu.fi/Record/nbnfioulu-202308192946' link='https://github.com/ouspg/SOP-Robot/tree/521275A-2023-speech-synthesis' title='Finnish TTS-system for a social robot' description='Developed a Finnish-speaking text-to-speech system for ROS2 robot as part of a bachelor`s degree project. During the project we had to study topics for example from deep-learning, neural networks, machine learning and neural network model training. We also had to do performance tests in an embedded system.'></ProjectCard>
                <ProjectCard tech='MongoDB, Express.js, React, Node.js' live='https://proud-night-7676.fly.dev/' link='https://github.com/laurilako/journeys' title='JourneyApp' description='Journey App, where you can track travelled city bike journeys and stations'/>
                <ProjectCard tech='MongoDB, Express.js, React, Node.js' live='' link='https://github.com/laurilako/itori' title='Tori' description='Full stack web application which implements basic CRUD actions'/>
                <ProjectCard tech='.NET CORE, C#, AzureDB' live='' link="https://github.com/laurilako/Feedback" title='Feedback API' description="RESTful API created using .NET CORE"/>
              </div>
          </div>
          <Footer />
        </div>
      </div>
    </body>
  );
}

export default App;
